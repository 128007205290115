import { createContext, ReactNode, useContext, useState } from "react";
import { NextFont, NextFontWithVariable } from "next/dist/compiled/@next/font";
import { usePostHog } from "posthog-js/react";
import { trpc } from "@/utils/trpc";
export interface UserPreference {
  setUserFont: (font: NextFontWithVariable, save: boolean) => void;
}
const UserPreferenceProviderContext = createContext<UserPreference>({
  setUserFont: () => {},
} as UserPreference);

export const useUserPreference = () => {
  const context = useContext(UserPreferenceProviderContext);
  if (typeof context === "undefined") {
    throw new Error(
      "usePersonaDemo must be used within a PersonaUserPreferenceProvider",
    );
  }
  return context;
};

export const UserPreferenceProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const posthog = usePostHog();
  const updateFont = trpc.users.updatePreferredFont.useMutation();
  const trpcUtils = trpc.useUtils();
  const [demoState, setUserPreference] = useState<UserPreference>({
    setUserFont: () => {},
  } as UserPreference);
  demoState.setUserFont = (font, save) => {
    console.log("saving font", save);
    if (save) {
      updateFont.reset();
      // updateFont.mutate(
      //   {},
      //   {
      //     onSettled: () => trpcUtils.users.invalidate(),
      //   },
      // );
    }
  };
  return (
    <UserPreferenceProviderContext.Provider value={demoState}>
      {children}
    </UserPreferenceProviderContext.Provider>
  );
};
