import { useUserProfile } from "@/utils/auth/useUserProfile";
import { createBrowserClient } from "@supabase/ssr";
import { AuthChangeEvent, Session, User } from "@supabase/supabase-js";
import { useEffect, useState } from "react";

export function useSupabaseClient() {
  const supabase = createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  );

  return supabase;
}

export function useUser() {
  const [user, setUser] = useState<User | null>(null);
  const supabase = useSupabaseClient();
  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(
      (event: AuthChangeEvent, session: Session | null) => {
        console.log(event, session?.user);
        setUser((prevUser) => {
          if (!prevUser && session?.user) {
            return session.user;
          }
          if (prevUser && (!session?.user || prevUser.id !== session.user.id)) {
            return session?.user ?? null;
          }
          return prevUser;
        });
      },
    );
    return () => {
      subscription?.unsubscribe();
    };
  }, []);
  return user;
}
