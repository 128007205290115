import {
  httpBatchLink,
  httpLink,
  loggerLink,
  splitLink,
  unstable_httpBatchStreamLink,
  unstable_httpSubscriptionLink,
} from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { AppRouter } from "@/app/api/trpc/[trpc]/route.api";
import superjson from "superjson";
import { queryClient } from "@/pages/_app.page";
function getBaseUrl() {
  if (typeof window !== "undefined")
    // browser should use relative path
    return "";

  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`;

  if (process.env.RENDER_INTERNAL_HOSTNAME)
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}
import { createTRPCReact } from "@trpc/react-query";
import { transformer } from "./trpcTransformer";

export const trpc = createTRPCNext<AppRouter>({
  transformer: transformer,
  config(opts) {
    return {
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink(),
        splitLink({
          // uses the httpSubscriptionLink for subscriptions
          condition: (op) => op.type === "subscription",
          true: unstable_httpSubscriptionLink({
            url: `/api/trpc`,
            transformer: superjson,
          }),
          false: unstable_httpBatchStreamLink({
            url: `/api/trpc`,
            transformer: superjson,
          }),
        }),
      ],
    };
  },
  ssr: false,
});
