import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { usePostHog } from "posthog-js/react";
import { sendGAEvent } from "@next/third-parties/google";

interface AnalyticsCallbacks {
  capture: <T extends EventName>(
    event: T,
    metadata: T extends `${infer Category}.${infer Event}`
      ? EventMetadata[Category & keyof EventMetadata][Event &
          keyof EventMetadata[Category & keyof EventMetadata]]
      : never,
  ) => void;
}
const AnalyticsContext = createContext<AnalyticsCallbacks | null>(null);

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === null) {
    throw new Error(
      "AnalyticsContext must be used within an Analytics Provider",
    );
  }
  return context!;
};

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const posthog = usePostHog();
  return (
    <AnalyticsContext.Provider
      value={{
        capture: (event, metadata) => {
          console.log("capture", event, metadata);
          sendGAEvent("event", event.replaceAll(".", "_"), metadata);
          posthog.capture(event, metadata);
        },
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
