import React, { CSSProperties } from "react";

const SafeAreaContainer = ({ children }: { children: React.ReactNode }) => {
  const containerStyle: CSSProperties = {
    backgroundColor: "var(--gray-1)",
    position: "fixed",
    top: "env(safe-area-inset-top)",
    right: "env(safe-area-inset-right)",
    bottom: "env(safe-area-inset-bottom)",
    left: "env(safe-area-inset-left)",
    overflowY: "auto",
    width:
      "calc(100% - env(safe-area-inset-left) - env(safe-area-inset-right))",
    height:
      "calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
  };

  return <>{children}</>;
};

export default SafeAreaContainer;
